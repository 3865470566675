<template>
    <div style="flex:1;display: flex;flex-direction: column;">
        <div style="height: 350px;" ref="myPage">
            <RelationGraph ref="rg_gcgl" :options="options" :on-node-click="nodeClick">
                <template #node="{ node }">
                    <div style="height: 100%;width: 100%;display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="flex:1; display: flex; justify-content: center;align-items: center;">{{ node.text }}</div>
                        <div style="display: flex;justify-content: space-around;background-color: white;">
                            <vxe-button v-if="node.id.indexOf('a') == -1" type="text" status="success" icon="vxe-icon-edit" @mousedown.native.stop @click.native.stop="nodeEdit(node)"></vxe-button>
                            <vxe-button v-if="node.id.indexOf('c') == -1" type="text" status="success" icon="vxe-icon-add" @mousedown.native.stop @click.native.stop="nodeAdd(node)"></vxe-button>
                            <vxe-button v-if="node.id.indexOf('a') == -1" type="text" status="danger" icon="vxe-icon-delete" @mousedown.native.stop @click.native.stop="nodeDel(node)"></vxe-button>
                        </div>
                    </div>

                </template>
            </RelationGraph>
        </div>
        <WXCW81ProjectChart :nodeId="nodeId" :projectId="gcId"></WXCW81ProjectChart>
        <el-dialog :visible.sync="visible2" :close-on-click-modal="false" :show-close="false" width="30%">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{
                    $t("lang.测位信息") }}</div>
            </template>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit2">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible2 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
            <el-form :model="model2" ref="form2" label-width="150px" label-position="left" :rules="rules2">
                <el-form-item :label="$t('lang.测位名称')" prop="name">
                    <el-input v-model="model2.name"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="visible3" :close-on-click-modal="false" :show-close="false" width="30%">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{
                    $t("lang.测点信息") }}</div>
            </template>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit3">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible3 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
            <el-form :model="model3" ref="form3" label-width="150px" label-position="left" :rules="rules3">
                <el-form-item :label="$t('lang.测点名称')" prop="sensorId">
                    <el-select v-model="model3.sensorId" :placeholder="$t('lang.请选择')" style="width:100%;">
                        <el-option v-for="(item, index) in myCompanySensor" :key="index" :label="item.sensorName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import RelationGraph from 'relation-graph';
import WXCW81ProjectChart from './WXCW81ProjectChart.vue';
export default {
    components: { RelationGraph, WXCW81ProjectChart },
    props: ["gcId", "cwId", "cdId"],

    data() {
        let that = this;
        return {
            visible2: false,
            model2: { name: "" },
            rules2: {
                name: [{ required: true, message: that.$t("lang.请输入测位名称"), trigger: "blur" }],
            },
            visible3: false,
            model3: { sensorId: null },
            rules3: {
                sensorId: [{ required: true, message: that.$t("lang.请选择"), trigger: "change" }],
            },
            nodeId: null,
            options: {
                // allowShowMiniToolBar: false,
                // disableDragNode: true,
                // disableZoom: false,//缩放
                disableLineClickEffect: true,//线被选中
                // disableDragCanvas: false,//拖动
                // graphOffset_y: "-50",
                disableDragNode: true,
                layout: {
                    layoutName: "tree",
                    levelDistance: "125",
                    min_per_height: "150",
                    max_per_height: "150",
                    min_per_width: "250"
                }
            },
            jsonData: {
                rootId: "",
                nodes: [],
                lines: []
            },
            myCompanySensor: []
        };
    },
    methods: {
        nodeAdd(node) {
            let that = this;
            if (node.id.indexOf("a") > -1) {
                if (that.$refs["form2"]) {
                    that.$refs["form2"].resetFields();
                }
                that.model2 = { docId: node.data.id };
                that.visible2 = true;
            }
            else if (node.id.indexOf("b") > -1) {
                if (that.$refs["form3"]) {
                    that.$refs["form3"].resetFields();
                }
                that.model3 = { docId: node.data.id };
                that.visible3 = true;
            }
        },
        nodeDel(node) {
            let that = this;
            if (node.id.indexOf("b") > -1) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("WXCW81/DelCWGL", { onlyInt: node.data.id }).then(function () {
                            that.loadProject();
                        });
                    })
                    .catch(() => { });
            } else if (node.id.indexOf("c") > -1) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("WXCW81/DelCDGL", { onlyInt: node.data.id }).then(function () {
                            that.loadProject();
                        });
                    })
                    .catch(() => { });
            }
        },
        nodeEdit(node) {
            let that = this;
            if (node.id.indexOf("b") > -1) {
                that.model2 = node.data;
                that.visible2 = true;
            }
            else if (node.id.indexOf("c") > -1) {
                //这里不能直接复制，因为里面有WXCW_WXCWSensor会编辑不成功
                that.model3.id = node.data.id;
                that.model3.docId = node.data.docId;
                that.model3.sensorId = node.data.sensorId;
                that.model3.createTime = node.data.createTime;
                that.visible3 = true;
            }
        },
        submit2() {
            let that = this;
            that.$refs["form2"].validate((valid) => {
                if (valid) {
                    that.axios.post("WXCW81/SaveCWGL", that.model2).then((response) => {
                        if (response.data.code == 101) {
                            that.loadProject();
                            that.visible2 = false;
                        }
                    });
                }
            });
        },
        submit3() {
            let that = this;
            that.$refs["form3"].validate((valid) => {
                if (valid) {
                    that.axios.post("WXCW81/SaveCDGL", that.model3).then((response) => {
                        if (response.data.code == 101) {
                            that.loadProject();
                            that.visible3 = false;
                        }
                    });
                }
            });
        },

        nodeClick(v) {
            this.nodeId = v.id;
        },
        loadProject() {
            let that = this;
            that.axios.post("WXCW81/GetProjectForRelationGraph", { onlyInt: that.gcId }).then((response) => {
                that.nodeId = that.jsonData.rootId = response.data.data.nodes[0].id;
                that.jsonData.nodes = response.data.data.nodes;
                that.jsonData.lines = response.data.data.lines;
                that.$refs.rg_gcgl.setJsonData(that.jsonData, () => { });
            });
        },
        getUserSensorList() {
            let that = this;
            that.axios.post("WXCW81/GetUserSensorList", {}).then((response) => {
                that.myCompanySensor = response.data.data;
            });
        },

    },
    mounted() {
        let that = this;
        that.loadProject();
        that.getUserSensorList();
    }
};
</script>

<style></style>