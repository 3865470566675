<template>
    <div style="flex:1;display: flex;" v-loading="loading" :element-loading-text="$t('lang.加载中')" element-loading-spinner="el-icon-loading">
        <div style="flex:1;">
            <div id="wxcw81_chart_project"></div>
        </div>
        <div style="flex: 1; display: flex;flex-direction: column;max-width: 50%;">
            <el-descriptions :column="1" :border="true">
                <el-descriptions-item :contentStyle="{ textAlign: 'center' }">
                    <template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.选择日期") }}</template>
                    <el-date-picker v-model="searchRange" @change="loadProject" :clearable="false" type="datetimerange" :picker-options="pickerOptions" :range-separator="$t('lang.至')" :start-placeholder="$t('lang.开始日期')" :end-placeholder="$t('lang.结束日期')" align="right">
                    </el-date-picker>
                    <el-button type="success" icon="el-icon-refresh" @click="loadProject" style="margin-left: 10px;">{{ $t("lang.刷新") }}</el-button>
                    <el-button type="primary" icon="el-icon-download" @click="exportExcel" style="margin-left: 10px;">{{ $t("lang.导出峰值数据") }}</el-button>
                </el-descriptions-item>
            </el-descriptions>
            <el-tabs style="flex:1;" :value="projectData && projectData.length > 0 ? projectData[0].name : ''">
                <el-tab-pane v-for="(item, index) in  projectData " :key="index" :label="item.name" :name="item.name">
                    <el-descriptions :column="2" :border="true" :labelStyle="{ width: '150px' }" :contentStyle="{ width: '150px' }">
                        <el-descriptions-item>
                            <template slot="label"><i class="iconfont icon-tongdaoguanli"></i>{{ $t("lang.测点名称") }}</template>{{ item.name }}
                        </el-descriptions-item>
                        <el-descriptions-item content-class-name="cd-description">
                            <template slot="label"><i class="iconfont icon-pingjunzhi"></i>{{ $t("lang.平均值") }}</template>{{ item.avg_Temperature }}℃
                        </el-descriptions-item>
                        <el-descriptions-item content-class-name="cd-description">
                            <template slot="label"><i class="iconfont icon-wendu"></i>{{ $t("lang.最新温度") }}</template>{{ item.last_Temperature }}℃
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.返回时间") }}</template>{{ item.last_Temperature_Time }}
                        </el-descriptions-item>
                        <el-descriptions-item content-class-name="cd-description">
                            <template slot="label"><i class="iconfont icon-wendu"></i>{{ $t("lang.峰值") }}</template>
                            {{ item.max_Temperature }}℃
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.峰值时间") }}</template>
                            <el-badge :value="$t('lang.多次')" v-if="item.max_Temperature_Time.indexOf(',') > -1">
                                <el-tooltip effect="dark" placement="left">
                                    <div> {{ item.max_Temperature_Time.split(',')[0] }}<br /></div>
                                    <template #content>
                                        <div v-for="(item1, index1) in item.max_Temperature_Time.split(',')" :key="index1"> {{ index1 == 0 ? "" : item1 }}</div>
                                    </template>
                                </el-tooltip>
                            </el-badge>
                            <div v-else>{{ item.max_Temperature_Time }}</div>
                        </el-descriptions-item>
                        <el-descriptions-item content-class-name="cd-description">
                            <template slot="label"><i class="iconfont icon-wendu"></i>{{ $t("lang.谷值") }}
                            </template>{{ item.min_Temperature }}℃
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.谷值时间") }}</template>
                            <el-badge :value="$t('lang.多次')" v-if="item.min_Temperature_Time.indexOf(',') > -1">
                                <el-tooltip effect="dark" placement="left">
                                    <div> {{ item.min_Temperature_Time.split(',')[0] }}<br /></div>
                                    <template #content>
                                        <div v-for="(item1, index1) in item.min_Temperature_Time.split(',')" :key="index1"> {{ index1 == 0 ? "" : item1 }}</div>
                                    </template>
                                </el-tooltip>
                            </el-badge>
                            <div v-else>{{ item.min_Temperature_Time }}</div>
                        </el-descriptions-item>

                    </el-descriptions>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import HighCharts from "highcharts";
import moment from "moment";
import dark from "highcharts/themes/dark-unica";
import exportHighChart from "highcharts/modules/exporting";
import exportOffHighChart from "highcharts/modules/offline-exporting";
import HighchartsHanHua from "@/util/highcharts-lang-zh_CN.js";
exportHighChart(HighCharts);
exportOffHighChart(HighCharts);
dark(HighCharts);
HighchartsHanHua.HighchartsHanHua(HighCharts);
HighCharts.setOptions({ global: { useUTC: false } });

export default {
    props: ["nodeId", "projectId"],
    watch: {
        nodeId(v) {
            console.log(v);
        }
    },
    data() {
        let that = this;
        return {
            loading: false,
            searchRange: [],
            currentProject: {},
            pickerOptions: {
                shortcuts: [
                    {
                        text: "1" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "2" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-1, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "3" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-2, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "7" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-6, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "15" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-14, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "30" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-29, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: that.$t("lang.项目起止"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment(that.currentProject.beginDate).toDate(),
                                moment(that.currentProject.endDate).toDate(),
                            ]);
                        },
                    },
                ],
            },
            projectData: [],
        };
    },
    methods: {
        loadProject() {
            let that = this;
            if (!that.searchRange) {
                that.$message.error(that.$t("lang.请选择日期"));
                return;
            }
            that.loading = true;
            that.axios.post("WXCW81/LoadProject", {
                projectId: that.projectId,
                beginDate: that.searchRange[0],
                endDate: that.searchRange[1],
            }).then((response) => {
                that.loading = false;
                that.projectData = response.data.data;
                that.loadCharts();
            });
        },
        loadCharts() {
            //console.log(mapData);
            let that = this;
            HighCharts.chart("wxcw81_chart_project", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                tooltip: {
                    formatter: function () {
                        return (
                            this.series.name +
                            "<br />" +
                            that.$t("lang.温度") +
                            "：" +
                            this.y +
                            "℃<br />" +
                            that.$t("lang.时间") +
                            "：" +
                            moment(this.x).format("YYYY-MM-DD HH:mm:ss")
                        );
                    },
                },
                plotOptions: { series: { turboThreshold: 0 } }, //大于一千个点要用这个。否则不显示。
                title: { text: that.$t("lang.温度曲线图") },
                credits: { enabled: false },
                //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
                yAxis: { title: { text: that.$t("lang.温度") } },
                xAxis: { type: "datetime" },
                series: that.projectData,
            });
        },
        exportExcel() {
            let that = this;
            if (that.searchRange && that.searchRange.length > 1) {
                let tmploading = that.$loading({
                    lock: true,
                    text: that.$t("lang.加载中"),
                    spinner: "el-icon-loading",
                });
                that.axios
                    .post(
                        "WXCW81/ExportExcelForProject",
                        {
                            projectId: that.projectId,
                            beginDate: that.searchRange[0],
                            endDate: that.searchRange[1],
                        },
                        {
                            responseType: "blob",
                        }
                    )
                    .then((response) => {
                        tmploading.close();
                        let b = new Blob([response.data], {
                            type: "application/vnd.ms-excel;charset=utf-8",
                        });
                        let downloadElement = document.createElement("a");
                        let href = window.URL.createObjectURL(b); // 创建下载的链接
                        downloadElement.href = href;
                        downloadElement.download =
                            that.currentProject.projectName +
                            "(" +
                            moment(that.searchRange[0]).format("YYYY-MM-DD HH:mm:ss") +
                            "-" +
                            moment(that.searchRange[1]).format("YYYY-MM-DD HH:mm:ss") +
                            ").xlsx"; // 下载后文件名
                        document.body.appendChild(downloadElement);
                        downloadElement.click(); // 点击下载
                        document.body.removeChild(downloadElement); // 下载完成移除元素
                        window.URL.revokeObjectURL(href); // 释放掉blob对象
                    });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择日期") });
            }
        },
    },
    mounted() {
        let that = this;
        that.axios.post("WXCW81/GetCurrentProject", { onlyInt: that.projectId }).then((response) => {
            that.currentProject = response.data.data;
            that.searchRange = [moment(that.currentProject.beginDate).toDate(), moment(that.currentProject.endDate).toDate(),];
            that.loadProject();
        });
    }
};
</script>

<style></style>